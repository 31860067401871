import React from "react";
// import Stage from "../components/info-site/Stage";
import Layout from "../layouts/en";
import Seo from "../components/info-site/Seo";
// import HomepageHowItWorks from "../components/info-site/HomepageHowItWorks";
// import StageContent from "../components/info-site/StageContent";

// import BrandPartnerships from "../components/info-site/BrandPartnerships";
// import SideContentWithImg from "../components/info-site/SideContentWithImg";

// import gwpMainFeatures from "../data/gwpMainFeatures";
// import GetQuote from "../components/info-site/GetQuote";
// import BiggestChallanges from "../components/info-site/BiggestChallanges";

// import styles from "../styles/tyviso-gwp.module.css"
import ProductHeader from "../components/info-site/ProductHeader";
import ProductBenefits from "../components/info-site/ProductBenefits";
import ProductHowItWorks from "../components/info-site/ProductHowItWorks";
import ProductReview from "../components/info-site/ProductReview";
import GetStarted from "../components/info-site/GetStarted";

import EE from '../assets/ee-black.png'
import Wild from '../assets/wild-black.png'
import SS from '../assets/secret-sales.png'
import ClearPay from '../assets/clearpay.png'
import PlusNet from '../assets/plusnet-black.png'

import ProdBenefits1 from '../assets/product-benefits-1.png'
import ProdBenefits2 from '../assets/product-benefits-2.png'
import ProdBenefits3 from '../assets/product-benefits-3.png'
import ProdBenefits4 from '../assets/product-benefits-4.png'
import ProdBenefits5 from '../assets/product-benefits-5.png'

import PartnersLogoImg from '../assets/partners-logo-tr.png'
import ClaimGiftImg from '../assets/claim-gift-tr.png'
import FreeGift from '../assets/product-gwp-2.png'

import O4b from '../assets/o4b-logo.png'

const TyvisoGWP = props => {
    return (
			<Layout location={props.location}>
				<Seo titleId="gwpTitle" descriptionId="gwpDescription" />
        
        <ProductHeader
					title="gwpProductHeaderTitle"
					desc="gwpProductHeaderDesc"
          img={FreeGift}
          list={[
            'One click activation',
            'No monthly fees',
            'No setup fees',
          ]}
          trusted="Trusted by 500+ brands"
					partners={[
						{
							id: 'ee',
							img: EE,
						},
						{
							id: 'wild',
							img: Wild,
						},
						{
							id: 'ss',
							img: SS,
						},
						{
							id: 'clearpay',
							img: ClearPay,
						},
						{
							id: 'plusnet',
							img: PlusNet,
						},
					]}
				/>

        <ProductBenefits
					title="gwpProductBenefitsTitle"
          list={[
            {
              icon: ProdBenefits1,
              text: 'Earn more revenue per session at lightspeed'
            },
            {
              icon: ProdBenefits2,
              text: 'Drive more acquisitions from your existing traffic'
            },
            {
              icon: ProdBenefits3,
              text: 'Deliver dynamic experiences based on behaviour'
            },
            {
              icon: ProdBenefits4,
              text: 'Build increased trust and loyalty with all your shoppers'
            },
            {
              icon: ProdBenefits5,
              text: 'Gather rich insights about brand and product preferences'
            }
          ]}
        />

        <ProductHowItWorks
					title="gwpProductHowItWorksTitle"
          list={[
            {
              img: PartnersLogoImg,
              title: '1. Select',
              text: 'Choose from existing and exclusive offers of who you want to partner with'
            },
            {
              img: ClaimGiftImg,
              title: '2. Push',
              text: 'Drop & drag  effortlessly on your site using our tech in 30 seconds '
            },
            {
              img: FreeGift,
              title: '3. Engage',
              text: 'Watch your customers interact and your revenue catapult'
            },
          ]}
        />

        <ProductReview
          left={{
            title: 'Words from friends',
            quote: 'GWP has been pivotal in raising our customer engagement. The rise we’ve seen in basket completion and AOV is remarkable.',
            logo: O4b
          }}
          right={{
            title: 'Stats from case studies',
            list: [
              '11% increase in site revenue',
              '6% lift in basket completion',
              '20% raise in AOV',
              '£2.5 extra revenue / session',
            ]
          }}
        />
        <GetStarted />
				{/* <Stage
					title="gwpTitle"
					icon
					withoutMedia
					classTitle={styles.title}
					// noWrapTitle
				>
					<StageContent
						title="gwpSideTitle"
						ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
						ctaText="homepageStagePrimaryCta"
						// wideText
						list={[
							'gwpSideListItemOne',
							'gwpSideListItemTwo',
							'gwpSideListItemThree',
						]}
					/>
				</Stage>

				<HomepageHowItWorks
					title="gwpHowItWorksTitle"
					steps={[
						{
							id: 'gwpHowItWorksOne',
							icon: 'blue',
						},
						{
							id: 'gwpHowItWorksTwo',
							icon: 'turquoise',
						},
						{
							id: 'gwpHowItWorksThree',
						},
					]}
				/>

				<BrandPartnerships title="mainFeatures">
					{gwpMainFeatures.map(({ id, ...rest }) => (
						<SideContentWithImg lightParagraph key={id} {...rest} />
					))}
				</BrandPartnerships>

				<BiggestChallanges
					title="goals"
					centered
					cards={[
						{
							id: 'cpa',
							link: '/your-goals/acquire-new-customers',
							text: 'biggestChallangesCardOneTitle',
						},
						{
							id: 'ltv',
							link: '/your-goals/raise-customer-lifetime',
							text: 'biggestChallangesCardTwoTitle',
						},
						{
							id: 'revshare',
							link: '/your-goals/monetise-your-ecommerce',
							text: 'biggestChallangesCardThreeTitle',
						},
						{
							id: 'cro',
							link: '/your-goals/uplift-basket-completion',
							text: 'biggestChallangesCardFourTitle',
						},
					]}
				/>

				<GetQuote
					title="gwpQuoteTitle"
					desc="gwpQuoteDesc"
					cta="gwpQuoteCta"
				/> */}
			</Layout>
		);
};

export default TyvisoGWP;